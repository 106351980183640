import axios from "./api";

const FinishedService = {
  async getFinished() {
    const { data } = await axios.get("/finished");
    return data;
  },
};

export default FinishedService;
