import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  production: [],
  error: null,
};

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    getProductionStart: (state) => {
      state.isLoading = true;
    },
    getProductionSuccess: (state, action) => {
      state.isLoading = false;
      state.production = action.payload;
    },
    getProductionFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getProductionStart,
  getProductionSuccess,
  getProductionFailure,
} = productionSlice.actions;

export default productionSlice.reducer;
