import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  jersey: [],
  error: null,
};

export const jerseySlice = createSlice({
  name: "jersey",
  initialState,
  reducers: {
    getJerseyStart: (state) => {
      state.isLoading = true;
    },
    getJerseySuccess: (state, action) => {
      state.isLoading = false;
      state.jersey = action.payload;
    },
    getJerseyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getJerseyStart, getJerseySuccess, getJerseyFailure } =
  jerseySlice.actions;

export default jerseySlice.reducer;
