import "./finished.scss";
import { Container } from "react-bootstrap";
import img1 from "../../assets/products/1.webp";
import img2 from "../../assets/products/2.webp";
import img3 from "../../assets/products/3.webp";
import img4 from "../../assets/products/4.webp";
import img5 from "../../assets/products/5.webp";
import k1 from "../../assets/products/k1.webp";
import k2 from "../../assets/products/k2.webp";
import k3 from "../../assets/products/k3.webp";
import k4 from "../../assets/products/k4.webp";
import k5 from "../../assets/products/k5.webp";
import w1 from "../../assets/products/w1.webp";
import w2 from "../../assets/products/w2.webp";
import w3 from "../../assets/products/w3.webp";
import w4 from "../../assets/products/w4.webp";
import w5 from "../../assets/products/w5.webp";
import a1 from "../../assets/products/a1.webp";
import a2 from "../../assets/products/a2.webp";
import a3 from "../../assets/products/a3.webp";
import a4 from "../../assets/products/a4.webp";
import a5 from "../../assets/products/a5.webp";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LangContext } from "../../App";
import {
  getFinishedFailure,
  getFinishedStart,
  getFinishedSuccess,
} from "../../slice/finishedSlice";
import FinishedService from "../../services/finished";

export default function Finished() {
  const dispatch = useDispatch();
  const { finished } = useSelector((state) => state.finished);
  const lang = useContext(LangContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const getWorkshop = async () => {
      dispatch(getFinishedStart());
      try {
        const response = await FinishedService.getFinished();
        dispatch(getFinishedSuccess(response.items));
      } catch (error) {
        dispatch(getFinishedFailure(error));
      }
    };
    getWorkshop();
  }, [dispatch]);

  return (
    <Container fluid className="finished my-5">
      <Container>
        {finished.map((item) =>
          (() => {
            if (lang === "en") {
              return (
                <div
                  key={item.id}
                  className="page-title pb-4"
                  dangerouslySetInnerHTML={{ __html: item.text_en }}
                ></div>
              );
            } else if (lang === "sa") {
              if (item.text_sa) {
                return (
                  <div
                    key={item.id}
                    className="page-title pb-4"
                    dangerouslySetInnerHTML={{ __html: item.text_sa }}
                  />
                );
              } else {
                return (
                  <div
                    key={item.id}
                    className="page-title pb-4"
                    dangerouslySetInnerHTML={{ __html: item.text_en }}
                  />
                );
              }
            }
          })()
        )}
        <div className="finished-item">
          <div className="finished-big-item">
            <img src={img1} className="finished-big-img" alt="Asrimtex" />
          </div>
          <div className="finished-small-item">
            <img src={img2} className="finished-small-img" alt="Asrimtex" />
            <img src={img3} className="finished-small-img" alt="Asrimtex" />
            <img src={img4} className="finished-small-img" alt="Asrimtex" />
            <img src={img5} className="finished-small-img" alt="Asrimtex" />
          </div>
        </div>
        <div className="finished-item">
          <div className="finished-small-item">
            <img src={k2} className="finished-small-img" alt="Asrimtex" />
            <img src={k3} className="finished-small-img" alt="Asrimtex" />
            <img src={k4} className="finished-small-img" alt="Asrimtex" />
            <img src={k5} className="finished-small-img" alt="Asrimtex" />
          </div>
          <div className="finished-big-item">
            <img src={k1} className="finished-big-img" alt="Asrimtex" />
          </div>
        </div>
        <div className="finished-item">
          <div className="finished-big-item">
            <img src={w1} className="finished-big-img" alt="Asrimtex" />
          </div>
          <div className="finished-small-item">
            <img src={w2} className="finished-small-img" alt="Asrimtex" />
            <img src={w3} className="finished-small-img" alt="Asrimtex" />
            <img src={w4} className="finished-small-img" alt="Asrimtex" />
            <img src={w5} className="finished-small-img" alt="Asrimtex" />
          </div>
        </div>
        <div className="finished-item">
          <div className="finished-small-item">
            <img src={a2} className="finished-small-img" alt="Asrimtex" />
            <img src={a3} className="finished-small-img" alt="Asrimtex" />
            <img src={a4} className="finished-small-img" alt="Asrimtex" />
            <img src={a5} className="finished-small-img" alt="Asrimtex" />
          </div>
          <div className="finished-big-item">
            <img src={a1} className="finished-big-img" alt="Asrimtex" />
          </div>
        </div>
      </Container>
    </Container>
  );
}
