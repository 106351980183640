import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  menu: [],
  error: null,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    getMenuStart: (state) => {
      state.isLoading = true;
    },
    getMenuSuccess: (state, action) => {
      state.isLoading = false;
      state.menu = action.payload;
    },
    getMenuFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getMenuStart, getMenuSuccess, getMenuFailure } =
  menuSlice.actions;

export default menuSlice.reducer;
