import { Col, Container, Row } from "react-bootstrap";
import "./products.scss";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LangContext } from "../../App";
import {
  getProductionFailure,
  getProductionStart,
  getProductionSuccess,
} from "../../slice/productionSlice";
import ProductionService from "../../services/production";
import { API_URL } from "../../services/api";

export default function Products() {
  const dispatch = useDispatch();
  const { production } = useSelector((state) => state.production);
  const lang = useContext(LangContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const getProduction = async () => {
      dispatch(getProductionStart());
      try {
        const response = await ProductionService.getProduction();
        dispatch(getProductionSuccess(response.items));
      } catch (error) {
        dispatch(getProductionFailure(error));
      }
    };
    getProduction();
  }, [dispatch]);

  return (
    <Container fluid className="products my-5">
      <Col lg="10" className="mx-auto">
        {production.map((item) =>
          (() => {
            if (item.type === "production_title") {
              if (lang === "en") {
                return (
                  <div
                    key={item.id}
                    className="page-title pb-4"
                    dangerouslySetInnerHTML={{ __html: item.text_en }}
                  />
                );
              } else if (lang === "sa") {
                if (item.text_sa) {
                  return (
                    <div
                      key={item.id}
                      className="page-title pb-4"
                      dangerouslySetInnerHTML={{ __html: item.text_sa }}
                    />
                  );
                } else {
                  return (
                    <div
                      key={item.id}
                      className="page-title pb-4"
                      dangerouslySetInnerHTML={{ __html: item.text_en }}
                    />
                  );
                }
              }
            }
          })()
        )}
        {production.map((item) =>
          (() => {
            if (item.type === "production_text") {
              if (lang === "en") {
                return (
                  <div
                    key={item.id}
                    className="page-title"
                    dangerouslySetInnerHTML={{ __html: item.text_en }}
                  />
                );
              } else if (lang === "sa") {
                if (item.text_sa) {
                  return (
                    <div
                      key={item.id}
                      className="page-title"
                      dangerouslySetInnerHTML={{ __html: item.text_sa }}
                    />
                  );
                } else {
                  return (
                    <div
                      key={item.id}
                      className="page-title"
                      dangerouslySetInnerHTML={{ __html: item.text_en }}
                    />
                  );
                }
              }
            }
          })()
        )}
        <Row>
          {production.map((item) =>
            (() => {
              if (item.type === "production_item") {
                if (lang === "en") {
                  return (
                    <Col lg="6" key={item.id}>
                      <div className="products-item">
                        <Link to={`/${item.url}`}>
                          <img
                            src={API_URL + item.img}
                            alt=""
                            className="products-img"
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text_en }}
                            className="text-center py-4"
                          />
                        </Link>
                      </div>
                    </Col>
                  );
                } else if (lang === "sa") {
                  if (item.text_sa) {
                    return (
                      <Col lg="6" key={item.id}>
                        <div className="products-item">
                          <Link to={`/${item.url}`}>
                            <img
                              src={API_URL + item.img}
                              alt=""
                              className="products-img"
                            />
                            <div
                              dangerouslySetInnerHTML={{ __html: item.text_sa }}
                              className="text-center py-4"
                            />
                          </Link>
                        </div>
                      </Col>
                    );
                  } else {
                    return (
                      <Col lg="6" key={item.id}>
                        <div className="products-item">
                          <Link to={`/${item.url}`}>
                            <img
                              src={API_URL + item.img}
                              alt=""
                              className="products-img"
                            />
                            <div
                              dangerouslySetInnerHTML={{ __html: item.text_en }}
                              className="text-center py-4"
                            />
                          </Link>
                        </div>
                      </Col>
                    );
                  }
                }
              }
            })()
          )}
        </Row>
      </Col>
    </Container>
  );
}
