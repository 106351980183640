import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  contact: [],
  error: null,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    getContactStart: (state) => {
      state.isLoading = true;
    },
    getContactSuccess: (state, action) => {
      state.isLoading = false;
      state.contact = action.payload;
    },
    getContactFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getContactStart, getContactSuccess, getContactFailure } =
  contactSlice.actions;

export default contactSlice.reducer;
