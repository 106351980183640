import axios from "./api";

const ProductionService = {
  async getProduction() {
    const { data } = await axios.get("/production");
    return data;
  },
};

export default ProductionService;
