import React, { Suspense, useEffect, lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Loader from "./components/Loader/Loader";
import Finished from "./pages/Finished/Finished";
import Products from "./pages/Products/Products";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import {
  getHomeFailure,
  getHomeStart,
  getHomeSuccess,
} from "./slice/homeSlice";
import HomeService from "./services/home";
import { useDispatch } from "react-redux";
const Layout = lazy(() => import("./components/Layout/Layout"));
const Home = lazy(() => import("./components/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const Knitting = lazy(() => import("./pages/Knitting/Knitting"));
const Printing = lazy(() => import("./pages/Printing/Printing"));
const Embroidery = lazy(() => import("./pages/Embroidery/Embroidery"));
const Sewing = lazy(() => import("./pages/Sewing/Sewing"));
const Jersey = lazy(() => import("./pages/Jersey/Jersey"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Production = lazy(() => import("./pages/Production/Production"));

export const LangContext = React.createContext();

function App() {
  const [lang, setLang] = useState();
  const {
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({
      startEvent: "DOMContentLoaded",
      once: false,
      mirror: true,
      delay: 150,
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    const getHome = async () => {
      dispatch(getHomeStart());
      try {
        const response = await HomeService.getHome();
        dispatch(getHomeSuccess(response.items));
      } catch (error) {
        dispatch(getHomeFailure(error));
      }
    };
    getHome();
  }, [dispatch]);

  useEffect(() => {
    const localLang = localStorage.getItem("i18nextLng");
    setLang(localLang);
  }, [language]);

  return (
    <LangContext.Provider value={lang}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/about-us"
            element={
              <Suspense fallback={<Loader />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="/production-workshops"
            element={
              <Suspense fallback={<Loader />}>
                <Production />
              </Suspense>
            }
          />
          <Route
            path="/knitting-workshop"
            element={
              <Suspense fallback={<Loader />}>
                <Knitting />
              </Suspense>
            }
          />
          <Route
            path="/print-department"
            element={
              <Suspense fallback={<Loader />}>
                <Printing />
              </Suspense>
            }
          />
          <Route
            path="/embroidery-workshop"
            element={
              <Suspense fallback={<Loader />}>
                <Embroidery />
              </Suspense>
            }
          />
          <Route
            path="/sewing-workshop"
            element={
              <Suspense fallback={<Loader />}>
                <Sewing />
              </Suspense>
            }
          />
          <Route
            path="/production"
            element={
              <Suspense fallback={<Loader />}>
                <Products />
              </Suspense>
            }
          />
          <Route
            path="/jersey-fabric"
            element={
              <Suspense fallback={<Loader />}>
                <Jersey />
              </Suspense>
            }
          />
          <Route
            path="/finished-products"
            element={
              <Suspense fallback={<Loader />}>
                <Finished />
              </Suspense>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </LangContext.Provider>
  );
}

export default App;
