import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  workshop: [],
  error: null,
};

export const workshopSlice = createSlice({
  name: "workshop",
  initialState,
  reducers: {
    getWorkshopStart: (state) => {
      state.isLoading = true;
    },
    getWorkshopSuccess: (state, action) => {
      state.isLoading = false;
      state.workshop = action.payload;
    },
    getWorkshopFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getWorkshopStart, getWorkshopSuccess, getWorkshopFailure } =
  workshopSlice.actions;

export default workshopSlice.reducer;
