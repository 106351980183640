import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  about: [],
  error: null,
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    getAboutStart: (state) => {
      state.isLoading = true;
    },
    getAboutSuccess: (state, action) => {
      state.isLoading = false;
      state.about = action.payload;
    },
    getAboutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getAboutStart, getAboutSuccess, getAboutFailure } =
  aboutSlice.actions;

export default aboutSlice.reducer;
