import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../slice/homeSlice";
import menuReducer from "../slice/menuSlice";
import aboutReducer from "../slice/aboutSlice";
import workshopsReducer from "../slice/workshopsSlice";
import workshopReducer from "../slice/workshopSlice";
import productionReducer from "../slice/productionSlice";
import contactReducer from "../slice/contactSlice";
import jerseyReducer from "../slice/jerseySlice";
import finishedReducer from "../slice/finishedSlice";

export default configureStore({
  reducer: {
    home: homeReducer,
    menu: menuReducer,
    about: aboutReducer,
    workshops: workshopsReducer,
    production: productionReducer,
    contact: contactReducer,
    workshop: workshopReducer,
    jersey: jerseyReducer,
    finished: finishedReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
