import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  workshops: [],
  error: null,
};

export const workshopsSlice = createSlice({
  name: "workshops",
  initialState,
  reducers: {
    getWorkshopsStart: (state) => {
      state.isLoading = true;
    },
    getWorkshopsSuccess: (state, action) => {
      state.isLoading = false;
      state.workshops = action.payload;
    },
    getWorkshopsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getWorkshopsStart, getWorkshopsSuccess, getWorkshopsFailure } =
  workshopsSlice.actions;

export default workshopsSlice.reducer;
