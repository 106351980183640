import axios from "./api";

const HomeService = {
  async getHome() {
    const { data } = await axios.get("/home");
    return data;
  },
};

export default HomeService;
