import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  home: [],
  error: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getHomeStart: (state) => {
      state.isLoading = true;
    },
    getHomeSuccess: (state, action) => {
      state.isLoading = false;
      state.home = action.payload;
    },
    getHomeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getHomeStart, getHomeSuccess, getHomeFailure } =
  homeSlice.actions;

export default homeSlice.reducer;
