import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  finished: [],
  error: null,
};

export const finishedSlice = createSlice({
  name: "finished",
  initialState,
  reducers: {
    getFinishedStart: (state) => {
      state.isLoading = true;
    },
    getFinishedSuccess: (state, action) => {
      state.isLoading = false;
      state.finished = action.payload;
    },
    getFinishedFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { getFinishedStart, getFinishedSuccess, getFinishedFailure } =
  finishedSlice.actions;

export default finishedSlice.reducer;
